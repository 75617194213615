export default {
  name: "ArticlesComponent",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      tableData: [],
      iCurrentPage: 1,
      iItemsPerPage: 16,
      iNumPages: 0,
      iTotalItems: 0,
      screenHeight: 0,
      restantHeight: 0,
      texts: "",
      rating: 4.5,
      restantHeightName: 0,
      restantHeightLocation: 0,
      restantHeightPrice: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.articleTexts[this.selectLanguage];
    this.$store.commit("setsCategoryId", "");
    this.$store.commit("setsSubCategoryId", "");
    this.$store.commit("setAlphabeticallyStatus", null);
    this.$store.commit("setPriceStatus", null);
  },
  mounted() {
    this.$store.commit("setMenuSidebar", {active:false});
    this.fillTable();
  },
  updated() {
    this.resizeDivName();
    this.resizeDivLocation();
    this.resizeDivPrice();
    this.resizeDiv();
  },
  created() {
    window.addEventListener("load", this.contentTableHeight);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize", this.resizeDiv);
    this.resizeDiv();
    window.addEventListener("resize", this.resizeDivName);
    this.resizeDivName();
    window.addEventListener("resize", this.resizeDivLocation);
    this.resizeDivLocation();
    window.addEventListener("resize", this.resizeDivPrice);
    this.resizeDivPrice();
  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.resizeDiv);
    window.removeEventListener("resize", this.resizeDivName);
    window.removeEventListener("resize", this.resizeDivLocation);
    window.removeEventListener("resize", this.resizeDivPrice);
  },
  methods: {
    // muestra el detalle del articulo seleccionado
    detailItem(item) {
      this.$router.push("/admin/article-detail/" + item.sProductId);
    },
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
    },
    resizeDivName: function(text) {
      var contentHeight = document.getElementsByClassName("text-article-name");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightName = max;
    },
    resizeDivLocation: function(text) {
      var contentHeight = document.getElementsByClassName(
        "text-article-address"
      );
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightLocation = max;
      // return text;
    },
    resizeDivPrice: function() {
      var contentHeight = document.getElementsByClassName("text-article-price");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightPrice = max;
    },
    resizeDiv: function() {
      this.screenHeight = window.innerHeight - 400;

      var contentHeight = document.getElementsByClassName("text-height");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeight = max;
    },
    // muestra los articulos disponibles
    fillTable() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          sCategoryId: this.sCategoryId,
          sSubcategoryId: this.sSubcategoryId,
          aImagesSizes: "md",
          iImageLength: 1,
          // sPriceOrder: 'desc',
          // sAlphOrder:  '',
          sPriceOrder:
            this.priceStatus == false ? "desc" : this.priceStatus ? "asc" : "",
          sAlphOrder:
            this.alphabeticallyStatus == false
              ? "desc"
              : this.alphabeticallyStatus
              ? "asc"
              : "",
          dRatingMin: this.rangeStatus,
        },
      })
        .then((response) => {
          var i = 0;
          this.tableData = response.data.results.map((e) => {
            i++;
            return {
              ...e,
              aImages:
                e.aImages.length > 0
                  ? e.aImages[0].oImages.md
                  : require(`@/assets/images/EmptyImage.jpg`),
            };
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // convierte el numero en formato money
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
  },
  computed: {
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    sCategoryId() {
      return this.$store.state.sCategoryId;
    },
    sSubcategoryId() {
      return this.$store.state.sSubcategoryId;
    },
    rangeStatus() {
      return this.$store.state.bRangeStatus;
    },
    alphabeticallyStatus() {
      return this.$store.state.bAlphabeticallyStatus;
    },
    priceStatus() {
      return this.$store.state.bPriceStatus;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refreshTable: function() {
      this.fillTable();
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    sCategoryId: function() {
      this.fillTable();
    },
    sSubcategoryId: function() {
      this.fillTable();
    },
    iCurrentPage: function() {
      this.fillTable();
    },
    rangeStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    alphabeticallyStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    priceStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
      }
      this.fillTable();
    },
  },
};
